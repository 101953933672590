@mixin clearfix() {
	&::after {
		content: "";
		clear: both;
		display: table;
	}
}

@mixin config-bg-colors($prefix, $bg-color-...) {
	@each $i in $bg-color- {
		.#{$prefix}#{nth($i, 1)} {
			background: nth($i, 2);
		}
	}
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder { 
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
